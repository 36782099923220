const isValidFullName = (name) => {
  const validNamePattern = /^[a-zA-Z\s']+$/
  const nameParts = name.split(' ')
  return nameParts.length >= 2 && validNamePattern.test(name)
}

const isValidGroupName = (groupName) => {
  return groupName.length <= 50
}

const isValidPassword = (password) => {
  const minLength = 8
  const hasUpperCase = /[A-Z]/.test(password)
  const hasNumber = /[0-9]/.test(password)
  const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password) // eslint-disable-line no-useless-escape

  return password.length >= minLength && hasUpperCase && hasNumber && hasSpecialChar
}

const isValidEmail = (email) => {
  const validEmailPattern = /^[^\s@]+@(?!aol|excite|fastmail|gandi|gmail|gmx|hey|hotmail|hushmail|icloud|kolab|live|lycos|mail|mailfence|msn|outlook|passport|posteo|protonmail|rackspace|rediffmail|runbox|tutanota|yahoo|yandex|zoho)[^\s@]+\.[^\s@]+$/
  return validEmailPattern.test(email)
}

const isValidRequiredField = (field) => {
  return field !== ''
}

export {
  isValidEmail,
  isValidPassword,
  isValidFullName,
  isValidRequiredField,
  isValidGroupName
}
