import Highlight from "@tiptap/extension-highlight";
import Placeholder from "@tiptap/extension-placeholder";
import Underline from "@tiptap/extension-underline";
import {
  Editor,
  EditorContent as TTEditorContent,
  useEditor,
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useState } from "react";
import { useTranscriptJob } from "src/shared/context/TranscriptJobProvider";
import { Action, hotkeyOptions, useShortcut } from "src/shared/shortcuts";

const extensions = [
  StarterKit.configure({
    orderedList: false,
    bulletList: false,
  }),
  Underline,
  Placeholder.configure({
    showOnlyWhenEditable: false,
    placeholder: "An empty transcript...",
  }),
  Highlight,
];

const useToggleTextCase = (editor: Editor | null) => {
  const [lastCaseChange, setLastCaseChange] = useState<number>(0);

  const toTitleCase = (str: string): string => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const convertTextToCase = (text: string): string => {
    if (lastCaseChange % 3 === 0) {
      return text.toLowerCase();
    }
    if (lastCaseChange % 3 === 1) {
      return text.toUpperCase();
    }
    return toTitleCase(text);
  };

  const toggleTextCase = () => {
    if (!editor) return;
    const state = editor.state;
    const { from, to } = state.selection;
    const selectedText = state.doc.textBetween(from, to, " ");
    const modifiedText = convertTextToCase(selectedText);
    setLastCaseChange(lastCaseChange + 1);
    editor
      .chain()
      .focus()
      .deleteRange({ from, to })
      .insertContent(modifiedText)
      .run();
    editor.chain().focus().setTextSelection({ from, to }).run();
  };

  useShortcut(Action.ToggleTextCasing, toggleTextCase, hotkeyOptions, [
    editor,
    lastCaseChange,
  ]);
};

const useSelectNextMark = (editor: Editor | null) => {
  const selectNextMark = () => {
    if (!editor) return;
    const { state } = editor;

    let { to } = state.selection;
    const nextMark = state.doc.resolve(to).marks();

    if (nextMark) {
      let nextFrom = to;
      let nextTo = to;
      let nextMarkFound = false;

      state.doc.nodesBetween(to, state.doc.content.size, (node, pos) => {
        if (
          !nextMarkFound &&
          node.marks.some((mark) => mark.type.name === "highlight")
        ) {
          if (nextFrom === to) {
            nextFrom = pos;
          }
          nextTo = pos + node.nodeSize;
          nextMarkFound = true;
        }
      });

      if (!nextMarkFound) {
        state.doc.nodesBetween(0, state.doc.content.size, (node, pos) => {
          if (
            !nextMarkFound &&
            node.marks.some((mark) => mark.type.name === "highlight")
          ) {
            nextFrom = pos;
            nextTo = pos + node.nodeSize;
            nextMarkFound = true;
          }
        });
      }

      editor
        .chain()
        .focus()
        .setTextSelection({ from: nextFrom, to: nextTo })
        .run();
    }
  };

  useShortcut(
    Action.JumpToNextLowConfidenceWord,
    selectNextMark,
    hotkeyOptions,
    [editor]
  );
};

const useRapidMode = (editor: Editor | null) => {
  const { isRapidMode, setIsRapidMode } = useTranscriptJob();

  const toggleRapidMode = () => {
    setIsRapidMode(!isRapidMode);

    if (!editor) return;
    const { state } = editor;

    let nextFrom = 0;
    let nextTo = 0;
    let nextMarkFound = false;

    state.doc.nodesBetween(0, state.doc.content.size, (node, pos) => {
      if (
        !nextMarkFound &&
        node.marks.some((mark) => mark.type.name === "highlight")
      ) {
        nextFrom = pos;
        nextTo = pos + node.nodeSize;
        nextMarkFound = true;
      }
    });

    if (nextMarkFound) {
      editor
        .chain()
        .focus()
        .setTextSelection({ from: nextFrom, to: nextTo })
        .run();
    }
  };

  useShortcut(Action.ToggleRapidMode, toggleRapidMode, hotkeyOptions, [
    isRapidMode,
    editor,
  ]);
};

export const EditorContent = () => {
  const { content, setContent, isRapidMode, inProgress } = useTranscriptJob();

  const editor = useEditor(
    {
      extensions,
      content,
      autofocus: true,
      editable: inProgress,
      onUpdate: ({ editor }) => setContent(editor.getJSON()),
    },
    [inProgress]
  );

  useToggleTextCase(editor);
  useSelectNextMark(editor);
  useRapidMode(editor);

  useShortcut(
    Action.JumpToTranscriptEditor,
    () => editor?.chain().focus().run(),
    hotkeyOptions,
    [editor]
  );

  return (
    <div
      className={`ProseMirror-wrapper ${
        isRapidMode ? "rapid-mode-enabled" : ""
      }`}
    >
      <TTEditorContent editor={editor} />
    </div>
  );
};
