interface LocationProps {
  location?: {
    search?: string;
  };
}

interface SearchParams {
  email_address?: string;
  confirmation_code?: string;
  setup_account?: string;
  change_password?: string;
  [key: string]: string | undefined;
}

const getParamsFromLocationSearch = (props: LocationProps): SearchParams => {
  const { location: { search } = {} } = props
  const rawParams = new URLSearchParams(search)
  const decodedParams: SearchParams = {}

  rawParams.forEach((value, key) => {
    Object.assign(decodedParams, { [key]: value })
  })

  return decodedParams
}

export {
  getParamsFromLocationSearch
}